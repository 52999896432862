import { combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { Persistor } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import { RootState, RootStore } from './store.interface';

import settings from './settings/reducer';
import club from './club/reducer';
import user from './user/reducer';

const reducers = combineReducers({
  settings,
  club,
  user,
});

// Persist Store
const persistConfig = { key: 'cloud-system', storage };
const persistedReducer = persistReducer(persistConfig, reducers);

const builder = (): RootStore => {
  const store: RootState = createStore(persistedReducer);
  const persistor: Persistor = persistStore(store);

  return { store, persistor };
};

export default builder;
