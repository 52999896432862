import { Club } from '../../shared/API/clubs/club.interface';
import { SET_CLUB } from './action-types';
import { ClubAction, ClubReducer } from './club.interface';

const initialState = {} as Club;

function reducer(state = initialState, { type, payload }: ClubAction): ClubReducer {
  switch (type) {
    case SET_CLUB:
      return payload;

    default:
      return state;
  }
}

export default reducer;
