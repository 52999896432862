import { SET_USER } from './action-types';
import { UserAction, UserReducer } from './user.interface';

const initialState = {} as UserReducer;

function reducer(state = initialState, { type, payload }: UserAction): UserReducer {
  switch (type) {
    case SET_USER:
      return payload;

    default:
      return state;
  }
}

export default reducer;
