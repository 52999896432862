import React from 'react';
import ReactDOM from 'react-dom';
import AppRenderer from './AppRenderer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/bundle.min.css';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/js/brands.js';
import '@fortawesome/fontawesome-free/js/solid.js';
import '@fortawesome/fontawesome-free/js/fontawesome.js';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import './assets/global-styles.css';

ReactDOM.render(<AppRenderer />, document.getElementById('app'));
