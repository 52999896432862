import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import buildStore from './redux/store';
import { loadingStyles } from './shared/styles';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const { store, persistor } = buildStore(); // Persist Store

const loading = (
  <div className="row" style={loadingStyles}>
    <div className="loader-bubble loader-bubble-info m-6"></div>
  </div>
);

const AppRenderer: React.FC = (): JSX.Element => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={loading}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

export default AppRenderer;
