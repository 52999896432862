import { SET_SETTING_LANG } from './action-types';
import { SettingReducer, SettingAction } from './settings.interface';

const initialState: SettingReducer = {
  lang: 'es',
};

function reducer(state = initialState, { type, payload }: SettingAction): SettingReducer {
  switch (type) {
    case SET_SETTING_LANG:
      return { ...state, lang: payload };

    default:
      return state;
  }
}

export default reducer;
